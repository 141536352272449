<template>
  <tr>
    <td>
      {{ productName }}
      <span v-if="variantName">- {{variantName }}</span>
      <br><small>SKU: {{ sku }}</small>
    </td>
    <td class="text-center">{{ quantity }}</td>
  </tr>
</template>

<script>
import { get } from 'lodash-es'
import formatsCurrency from '@/mixins/formatsCurrency'
import orderStatus from '@/mixins/orderStatus'

export default {
  mixins: [formatsCurrency, orderStatus],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    productName () {
      return get(this.item, 'orderItem.productName')
    },
    variantName () {
      return get(this.item, 'orderItem.variantName')
    },
    sku () {
      return get(this.item, 'orderItem.variant.sku')
    },
    quantity () {
      return get(this.item, 'quantity', 1)
    }
  }
}
</script>
