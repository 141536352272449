<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="() => $store.dispatch('shipments/fetchById', {
          id: $route.params.id,
          storeId: currentStoreId
        })"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Expédition {{ reference }}</h2>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Sommaire</h4>
                <dl class="row">
                  <dt class="col-4">Commande</dt>
                  <dd class="col-8">{{ orderReference }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Date d'envoi</dt>
                  <dd class="col-8">{{ $shortDate(shipment.shippedAt) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Transporteur</dt>
                  <dd class="col-8">{{ shipment.carrierName }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Numéro de suivi</dt>
                  <dd class="col-8 d-flex align-items-center">
                    {{ shipment.trackingNumber }}
                    <a href="" @click.prevent="editTrackingNumber" class="ml-3">
                      <fa-icon :icon="['fas', 'pen']"></fa-icon>
                    </a>
                  </dd>
                </dl>
                <dl class="row" v-if="false">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">Expédié</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Adresse de livraison</h4>
                <p class="address">
                  {{ shippingAddress.name }}
                  <template v-if="shippingAddress.company">
                    <br> {{ shippingAddress.company }}
                  </template>
                  <br>{{ shippingAddress.address }}
                  <template v-if="shippingAddress.address2">
                    <br> {{ shippingAddress.address2 }}
                  </template>
                  <br>{{ shippingAddress.city }}, {{ shippingAddress.province }}, {{ shippingAddress.postalCode }}
                  <br>Canada
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body p-0">
                <h4 class="header-title with-padding">Articles envoyés</h4>

                <div class="table-responsive">
                  <table class="table nowrap-table">
                    <thead>
                    <th>Article</th>
                    <th class="col-100">Quantité</th>
                    </thead>
                    <tbody>
                    <template v-for="item in shipmentItems">
                      <shipment-item-row :item="item" :key="item.id"></shipment-item-row>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import ShipmentItemRow from '@/components/shipments/ShipmentItemRow'

// TODO: liste d'items envoyés

export default {
  mixins: [userData, formatsDate],
  components: {
    ShipmentItemRow
  },
  computed: {
    shipment () {
      return this.$store.getters['shipments/getDetailsById'](this.$route.params.id) || {}
    },
    shipmentItems () {
      return this.shipment ? this.shipment.shipmentItems : []
    },
    reference () {
      return get(this.shipment, 'reference')
    },
    orderReference () {
      return get(this.shipment, 'order.reference')
    },
    shippingAddress () {
      return get(this.shipment, 'shippingAddress', {})
    }
  },
  methods: {
    editTrackingNumber () {
      this.$modal.openFormModal({
        title: "Éditer l'envoi",
        fields: [
          {
            name: 'trackingNumber',
            label: 'Numéro de suivi'
          }
        ],
        initialValues: {
          trackingNumber: get(this.shipment, 'trackingNumber')
        },
        confirmLabel: 'Enregistrer',
        cancelLabel: 'Annuler',
        onSubmit: async ({ values, confirm, setErrors }) => {
          setErrors({})

          if (!values.trackingNumber) {
            setErrors({
              trackingNumber: ['Ce champ est requis.']
            })
            return
          }

          await this.$store.dispatch('shipments/updateTracking', {
            shipmentId: this.shipment.id,
            trackingNumber: values.trackingNumber
          })

          confirm()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  line-height: 24px;
}
</style>
