<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Créer une expédition</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <span class="btn btn-link btn-sm" @click.prevent="$router.go(-1)">
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </span>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" v-if="hasError">
        Une erreur interne s'est produite. Réessayez dans quelques instants.
      </div>

      <async-object
        should-fetch
        :fetch-method="() => $store.dispatch('orders/fetchById', {
          id: $route.params.orderId,
          storeId: currentStoreId
        })"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-header">Informations générales</div>
              <div class="card-body">
                <v-dynamic-form
                  :fields="fields"
                  :initial-values="defaultValues"
                  @change="handleChange"
                  :errors="formErrors"
                ></v-dynamic-form>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-header">Adresse de livraison</div>
              <div class="card-body">
                <p class="address">
                  {{ shippingAddress.name }}
                  <template v-if="shippingAddress.company">
                    <br> {{ shippingAddress.company }}
                  </template>
                  <br>{{ shippingAddress.address }}
                  <template v-if="shippingAddress.address2">
                    <br> {{ shippingAddress.address2 }}
                  </template>
                  <br>{{ shippingAddress.city }}, {{ shippingAddress.province }}, {{ shippingAddress.postalCode }}
                  <br>Canada
                </p>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card mb-4">
              <div class="card-header">Contenu de l'envoi</div>
              <div class="card-body p-0">
                <p class="p-3 m-0" :class="{'text-danger': showItemErrors}">
                  Veuillez cocher les articles qui figurent dans l'envoi.
                </p>

                <div class="table-responsive">
                  <table class="table nowrap-table">
                    <thead>
                    <tr>
                      <th class="col-check">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`check-all`"
                            :checked="checkAll || indeterminate"
                            :indeterminate.prop="indeterminate"
                            @click="toggleCheckAll"
                          >
                          <label class="custom-control-label" :for="`check-all`"></label>
                        </div>
                      </th>
                      <th>Article</th>
                      <th class="col-100">Quantité</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="orderItem in orderItems" :key="orderItem.id">
                      <td class="col-check">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`check-${orderItem.id}`"
                            :checked="isItemSelected(orderItem.id) && !isItemDisabled(orderItem.status)"
                            @click="toggleOrderItem(orderItem)"
                            :disabled="isItemDisabled(orderItem.status)"
                          >
                          <label class="custom-control-label" :class="{'check-error': showItemErrors}" :for="`check-${orderItem.id}`"></label>
                        </div>
                      </td>
                      <td :class="{'text-muted': isItemDisabled(orderItem.status)}">
                        {{ orderItem.productName }}
                        <span v-if="orderItem.variantName">- {{ orderItem.variantName }}</span>
                        <br><small>SKU: {{ getOrderItemSku(orderItem) }}</small>
                      </td>
                      <td class="text-center" :class="{'text-muted': isItemDisabled(orderItem.status)}">
                        {{ orderItem.quantity }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="mb-4">
              <button class="btn btn-primary" :disabled="isProcessing" @click="handleSubmit">Enregistrer</button>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import handleForm from '@/mixins/handle-form'
import userData from '@/mixins/user-data'
import useValidator from '@/mixins/useValidator'
import promptUnsaved from '@/mixins/promptUnsaved'

// TODO: preview avant submit (Post-MVP)

export default {
  mixins: [userData, handleForm, useValidator, promptUnsaved],
  data () {
    return {
      generalForm: {},
      shippedItems: [],
      isProcessing: false,
      showFormErrors: false,
      showItemErrors: false,
      hasError: false
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'shippedAt',
          label: "Date d'envoi",
          type: 'date-picker',
          required: true
        },
        {
          name: 'carrierName',
          label: 'Transporteur',
          type: 'carrier-selector',
          required: true
        },
        {
          name: 'otherCarrier',
          label: 'Nom du transporteur',
          required: true,
          isVisible: (values) => {
            return values.carrierName === 'other'
          }
        },
        {
          name: 'trackingNumber',
          label: 'Numéro de suivi',
          required: true
        }
      ]
    },
    formErrors () {
      return this.showFormErrors ? this.errors : {}
    },
    defaultValues () {
      return {
        shippedAt: new Date()
      }
    },
    order () {
      return this.$store.getters['orders/getDetailsById'](this.$route.params.orderId)
    },
    shippingAddress () {
      return get(this.order, 'shippingAddress', {})
    },
    orderItems () {
      return this.order ? this.order.items : []
    },
    indeterminate () {
      return this.shippedItems.length > 0 && this.shippedItems.length !== this.orderItems.filter(item => !this.isItemDisabled(item.status)).length
    },
    checkAll () {
      return this.shippedItems.length > 0 && this.shippedItems.length === this.orderItems.filter(item => !this.isItemDisabled(item.status)).length
    },
    validationRules () {
      return {
        shippedAt: 'required',
        carrierName: 'required',
        otherCarrier: 'required_if:carrierName,other',
        trackingNumber: 'required'
      }
    }
  },
  methods: {
    handleChange (values) {
      if (Object.entries(this.generalForm).length > 0) {
        this.formStatusChanged(true)
      }
      this.validate(values, this.validationRules)
      this.generalForm = { ...values }
    },
    getOrderItemSku (orderItem) {
      return get(orderItem, 'variant.sku')
    },
    isItemSelected (orderItemId) {
      return this.shippedItems.some((item) => item.orderItemId === orderItemId)
    },
    isItemDisabled (status) {
      return status !== 'processing'
    },
    toggleCheckAll () {
      if (!this.checkAll) {
        this.shippedItems = this.orderItems
          .filter(item => !this.isItemDisabled(item.status))
          .map(item => ({ orderItemId: item.id, quantity: item.quantity }))
      } else {
        this.shippedItems = []
      }
    },
    toggleOrderItem (orderItem) {
      if (this.isItemDisabled(orderItem.status)) {
        return
      }

      if (this.isItemSelected(orderItem.id)) {
        this.shippedItems = this.shippedItems.filter((item) => item.orderItemId !== orderItem.id)
      } else {
        this.shippedItems.push({
          orderItemId: orderItem.id,
          quantity: orderItem.quantity
        })
      }
    },
    async handleSubmit () {
      this.showFormErrors = Object.values(this.errors).length > 0
      this.showItemErrors = !this.shippedItems.length

      if (this.showFormErrors || this.showItemErrors) {
        return
      }

      this.isProcessing = true
      this.hasError = false

      try {
        await this.$store.dispatch('shipments/create', {
          storeId: this.currentStoreId,
          orderId: this.order.orderId,
          shippedAt: this.generalForm.shippedAt,
          carrierName: this.generalForm.carrierName === 'other' ? this.generalForm.otherCarrier : this.generalForm.carrierName,
          trackingNumber: this.generalForm.trackingNumber,
          items: [...this.shippedItems]
        })

        this.formStatusChanged(false)

        this.$router.push({ name: 'shipments.index' })
      } catch (e) {
        this.hasError = true
        this.isProcessing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col-check {
  vertical-align: middle;
}

.check-error {
  &::before {
    border: 1px solid #dc3545;
  }
}
</style>
