import Vue from 'vue'

export default Vue.extend({
  methods: {
    getOrderStatusText (value: string): string {
      switch (value) {
        case 'cancelled':
          return 'Annulée'
        case 'completed':
          return 'Finalisée'
        case 'partiallyShipped':
          return 'Partiellement expédiée'
        case 'dispatched':
        case 'paid':
        case 'pending':
        default:
          return 'En traitement'
      }
    },
    getOrderItemStatusText (value: string): string {
      switch (value) {
        case 'processing':
          return 'A expédier'
        case 'cancelled':
          return 'Annulé'
        case 'modified':
          return 'Modifié'
        case 'shipped':
          return 'Expédié'
        case 'returnRequested':
          return 'Retour demandé'
        case 'returnDenied':
          return 'Retour refusé'
        case 'pendingReturn':
          return 'A retourner'
        case 'returnExpired':
          return 'Retour expiré'
        case 'returned':
          return 'Retourné'
        default:
          return 'En traitement'
      }
    }
  }
})
