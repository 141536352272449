<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Expéditions</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="shipments"
          placeholder-text-filter="Chercher une expédition"
          :display-text-filter="true"
          :display-reset="true"
          @changePage="changePage"
        />

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="shipments"
              :show-actions="false"
              no-top-border
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              hover
              :link="(item) => `/shipments/${item.id}`"
              :empty-img-src="require('@/assets/images/icons/shipped.svg')"
              empty-message="Vos expéditions s'afficheront ici"
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination state="shipments"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :range="2"
                          :display-per-page="true"
                          @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import pagination from '@/mixins/pagination'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [userData, formatsDate, pagination],
  computed: {
    cols () {
      return [
        {
          header: 'Date',
          property: 'shippedAt',
          method: (item) => {
            return this.$shortDate(item.shippedAt)
          },
          isSortable: true
        },
        {
          header: "Numéro d'envoi",
          property: 'reference',
          isSortable: true
        },
        {
          header: 'Commande',
          property: 'order.reference',
          method: (item) => {
            return get(item, 'order.reference')
          },
          isSortable: true
        },
        {
          header: 'Destinataire',
          property: 'customer.firstName',
          method: (item) => {
            return `${get(item, 'order.customer.firstName')} ${get(item, 'order.customer.lastName')}`
          },
          isSortable: true
        },
        {
          header: 'Articles',
          property: 'totalQuantity',
          method: (item) => {
            let total = 0

            if (item.shipmentItems && Array.isArray(item.shipmentItems)) {
              for (const shipmentItems of item.shipmentItems) {
                total += shipmentItems.quantity
              }
            }

            return total
          }
        }
      ]
    },
    shipments () {
      return this.paginatedData.data
    }
  },
  methods: {
    async changePage (page) {
      await this.fetchShipments(page)
    },
    async fetchShipments (page) {
      await this.$store.dispatch('shipments/fetch', { page, storeId: this.currentStoreId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchShipments()
    }
  },
  async created () {
    this.instance = 'shipments'
    await this.fetchShipments()
  }
}
</script>
